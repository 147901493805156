import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface ITiresRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface ITireInfo {
  entries: number
  quantity: number
  formattedQuantity: string
}

export interface ITireItem {
  locationId: string
  locationName: string
  advantaTires: ITireInfo
  tiresInProgram: ITireInfo
  bfsTires: ITireInfo
  tires: ITireInfo
  tiresAndAccessories: ITireInfo
  totals: ITireInfo
}

export interface ITireItemExtended extends IUrlFilters {
  locationId?: string
  locationName?: string
  advantaTiresEntries: number
  advantaTiresQuantity: number
  advantaTiresPercentage: number
  advantaTiresFormattedQuantity: string
  tiresInProgramEntries: number
  tiresInProgramQuantity: number
  tiresInProgramPercentage: number
  tiresInProgramFormattedQuantity: string
  bfsTiresEntries: number
  bfsTiresQuantity: number
  bfsTiresPercentage: number
  bfsTiresFormattedQuantity: string
  tiresEntries: number
  tiresQuantity: number
  tiresFormattedQuantity: string
  tiresAndAccessoriesEntries: number
  tiresAndAccessoriesQuantity: number
  tiresAndAccessoriesFormattedQuantity: string
  totalsEntries: number
  totalsQuantity: number
  totalsFormattedQuantity: string
}

export interface ITiresResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireItem[]
  grandTotalAdvantaTires: ITireInfo
  grandTotalTiresInProgram: ITireInfo
  grandTotalBfsTires: ITireInfo
  grandTotalTires: ITireInfo
  grandTotalTiresAndAccessories: ITireInfo
  globalTotals: ITireInfo
}

export interface ITiresExtendedResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireItemExtended[]
  grandTotal: ITireItemExtended
}

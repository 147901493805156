import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatFormFieldAppearance } from '@angular/material/form-field'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { debounceTime } from 'rxjs'
import { IInputErrorMessages, IRangeDateValue } from '../input/input.interface'
import { globalErrorMessages } from '../input/input.constant'
import { isMoment } from 'moment'
import { TodayString } from '@lla-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'lla-input-range-date',
  templateUrl: './input-range-date.component.html',
  styleUrls: ['../input/input.component.scss']
})
export class InputRangeDateComponent implements OnInit, OnChanges {
  @Input() label: string
  @Input() prefix: string
  @Input() size: 'small' | 'default' = 'default'
  @Input() appearance: MatFormFieldAppearance = 'outline'
  @Input() debounceDuration = 0
  @Input() errorMessages: IInputErrorMessages[] = []
  @Input() customErrorMatcher: ErrorStateMatcher
  @Input() customError: {
    show: boolean
    message: string
  }
  @Input() autoFocus = false
  @Input() loading = false
  @Input() disabled = false
  @Input() icon: string
  @Input() iconColor = '#808080'
  @Input() iconClass = ''
  @Input() iconSize = '16px'
  @Input() maxDate? = TodayString()
  @Input() minDate?: Date
  @Input() initialValue?: IRangeDateValue

  @Output() valueChanged = new EventEmitter<IRangeDateValue>()
  @Output() iconClicked = new EventEmitter()

  rangeForm: UntypedFormGroup
  allErrorMessages: IInputErrorMessages[] = []

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.rangeForm = this.fb.group({
      from: [this.initialValue?.from],
      to: [this.initialValue?.to]
    })

    this.allErrorMessages = [...this.errorMessages, ...globalErrorMessages]
    this.rangeForm?.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.debounceDuration))
      .subscribe((value) => {
        this.valueChanged.emit({
          from: isMoment(value?.from) ? value.from.utcOffset(0, true).format() : value?.from,
          to: isMoment(value?.to) ? value.to.utcOffset(0, true).format() : value?.to
        })
      })
  }

  ngOnChanges() {
    if (this.loading || this.disabled) {
      this.rangeForm?.disable()
    } else {
      this.rangeForm?.enable()
    }
  }

  handleErrorMessage() {
    if (this.customError?.show && this.customError?.message) {
      return this.customError.message
    }
    const allErrors: ValidationErrors = this.rangeForm?.errors ?? {}
    return this.allErrorMessages.find((el) => allErrors[el.type])?.message ?? ''
  }

  isFormInvalid() {
    return (
      (this.customError?.show || this.rangeForm?.invalid) &&
      (this.rangeForm?.dirty || this.rangeForm?.touched)
    )
  }
}

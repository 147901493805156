import { ITimePeriod, IUrlFilters } from '@lla-platform/core/core-data-access'
import { ITireInfo } from './tires.interface'

export interface ITireByLevelItem {
  locationId: string
  locationName: string
  level1: ITireInfo
  level2: ITireInfo
  level3: ITireInfo
  level4: ITireInfo
  level5: ITireInfo
  level6: ITireInfo
  tires: ITireInfo
  tiresAndAccessories: ITireInfo
  tiresInLevel: ITireInfo
  totals: ITireInfo
}

export interface ITireByLevelItemExtended extends IUrlFilters {
  locationId?: string
  locationName?: string

  level1Entries: number
  level1Quantity: number
  level1Percentage: number
  level1FormattedQuantity: string

  level2Entries: number
  level2Quantity: number
  level2Percentage: number
  level2FormattedQuantity: string

  level3Entries: number
  level3Quantity: number
  level3Percentage: number
  level3FormattedQuantity: string

  level4Entries: number
  level4Quantity: number
  level4Percentage: number
  level4FormattedQuantity: string

  level5Entries: number
  level5Quantity: number
  level5Percentage: number
  level5FormattedQuantity: string

  level6Entries: number
  level6Quantity: number
  level6Percentage: number
  level6FormattedQuantity: string

  tiresInLevelEntries: number
  tiresInLevelQuantity: number
  tiresInLevelPercentage: number
  tiresInLevelFormattedQuantity: string

  tiresEntries: number
  tiresQuantity: number
  tiresFormattedQuantity: string

  tiresAndAccessoriesEntries: number
  tiresAndAccessoriesQuantity: number
  tiresAndAccessoriesFormattedQuantity: string

  totalsEntries: number
  totalsQuantity: number
  totalsFormattedQuantity: string
}

export interface ITiresByLevelResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByLevelItem[]
  grandTotalLevel1: ITireInfo
  grandTotalLevel2: ITireInfo
  grandTotalLevel3: ITireInfo
  grandTotalLevel4: ITireInfo
  grandTotalLevel5: ITireInfo
  grandTotalLevel6: ITireInfo
  grandTotalTires: ITireInfo
  grandTotalTiresAndAccessories: ITireInfo
  grandTotalTiresInLevel: ITireInfo
  globalTotals: ITireInfo
}

export interface ITiresByLevelExtendedResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByLevelItemExtended[]
  grandTotal: ITireByLevelItemExtended
}

import { ISelectItem } from '@lla-platform/core/core-ui'

export enum TireLevelType {
  NotSet = 'NotSet',
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
  Level4 = 'Level4',
  Level5 = 'Level5',
  Level6 = 'Level6'
}

export const TireLevelTypeItems: ISelectItem[] = [
  {
    label: 'Level 1',
    value: TireLevelType.Level1
  },
  {
    label: 'Level 2',
    value: TireLevelType.Level2
  },
  {
    label: 'Level 3',
    value: TireLevelType.Level3
  },
  {
    label: 'Level 4',
    value: TireLevelType.Level4
  },
  {
    label: 'Level 5',
    value: TireLevelType.Level5
  },
  {
    label: 'Level 6',
    value: TireLevelType.Level6
  }
]

import { environment } from '@lla-platform/core/core-util'

export const TIRES_API_URL = {
  tiresSummary: `${environment.apiUrl}/tires/summary-by-program`,
  exportTiresSummary: `${environment.apiUrl}/tires/summary-by-program/export`,
  tiresByLevelSummary: `${environment.apiUrl}/tires/summary-by-level`,
  exportTiresByLevelSummary: `${environment.apiUrl}/tires/summary-by-level/export`,
  tiresByStockableSummary: `${environment.apiUrl}/tires/summary-by-stockable`,
  exportTiresByStockableSummary: `${environment.apiUrl}/tires/summary-by-stockable/export`,
  tiresList: `${environment.apiUrl}/tires/filters/tires`,
  exportTiresList: `${environment.apiUrl}/tires/filters/tires/export`,
  updateTire: (itemId: string) => `${environment.apiUrl}/tires/update-tire/spl/${itemId}`,
  lostTire: `${environment.apiUrl}/tires/form`,
  lostTiresList: `${environment.apiUrl}/tires/filters/lost-tires`,
  exportLostTiresList: `${environment.apiUrl}/tires/filters/lost-tires/export`
}

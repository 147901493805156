import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
  CombinedGuard,
  CurrentUserGuard,
  LoggedInGuard,
  ProfileLayoutComponent,
  ServiceCategoriesGuard
} from '@lla-platform/core/core-feature'
import { ApplicationRoutes } from './app-routes-names'
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout/auth-layout.component'
import { MainLayoutComponent } from './layout/main-layout/main-layout/main-layout.component'
import { GoogleGuard } from './core/guards/google.guard'
import { PerformancePackageGpGuard } from './core/guards/performance-package-gp.guard'
import { PublicLayoutComponent } from './layout/public-layout/public-layout/public-layout.component'

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard },

  {
    path: '',
    canActivate: [CombinedGuard],
    data: {
      guards: [CurrentUserGuard, LoggedInGuard, ServiceCategoriesGuard]
    },
    component: MainLayoutComponent,
    children: [
      {
        path: ApplicationRoutes.dashboard,
        loadChildren: () =>
          import('./features/user-panel/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: ApplicationRoutes.receivable,
        loadChildren: () =>
          import('./features/user-panel/receivable/receivable.module').then((m) => m.ReceivabledModule)
      },
      {
        path: ApplicationRoutes.contacts,
        loadChildren: () =>
          import('./features/user-panel/customers/customers.module').then((m) => m.CustomersModule)
      },
      {
        path: ApplicationRoutes.shopPerformance,
        loadChildren: () =>
          import('./features/user-panel/shop-performance/shop-performance.module').then(
            (m) => m.ShopPerformanceModule
          )
      },
      {
        path: ApplicationRoutes.communications,
        loadChildren: () =>
          import('./features/user-panel/communications/communications.module').then(
            (m) => m.CommunicationsModule
          )
      },
      {
        path: ApplicationRoutes.inventories,
        loadChildren: () =>
          import('./features/user-panel/inventories/inventories.module').then((m) => m.InventoriesModule)
      },
      {
        path: ApplicationRoutes.payments,
        loadChildren: () =>
          import('./features/user-panel/payments/payments.module').then((m) => m.PaymentsModule)
      },
      {
        path: ApplicationRoutes.reviews,
        loadChildren: () =>
          import('./features/user-panel/reviews/reviews.module').then((m) => m.ReviewsModule)
      },
      {
        path: ApplicationRoutes.reports,
        loadChildren: () =>
          import('./features/user-panel/reports/reports.module').then((m) => m.ReportsModule)
      },
      {
        path: ApplicationRoutes.targets,
        loadChildren: () =>
          import('./features/user-panel/targets/targets.module').then((m) => m.TargetsModule)
      },
      {
        path: ApplicationRoutes.samples,
        loadChildren: () =>
          import('./features/user-panel/samples/samples.module').then((m) => m.SamplesModule)
      },
      {
        path: ApplicationRoutes.profile,
        component: ProfileLayoutComponent,
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: ApplicationRoutes.admin,
        loadChildren: () => import('./features/admin-panel/admin.module').then((m) => m.AdminModule)
      },
      {
        path: ApplicationRoutes.promotions,
        loadChildren: () =>
          import('./features/user-panel/promotions/promotions.module').then((m) => m.PromotionsModule)
      },
      {
        path: ApplicationRoutes.tires,
        loadChildren: () => import('./features/user-panel/tires/tires.module').then((m) => m.TiresModule)
      },
      {
        path: ApplicationRoutes.tracker,
        loadChildren: () =>
          import('./features/user-panel/tracker/tracker.module').then((m) => m.TrackerModule)
      },
      {
        path: ApplicationRoutes.whoToCall,
        loadChildren: () =>
          import('./features/user-panel/who-to-call/who-to-call.module').then((m) => m.WhoToCallModule)
      },
      {
        path: ApplicationRoutes.workOrders,
        loadChildren: () =>
          import('./features/user-panel/work-orders/work-orders.module').then((m) => m.WorkOrdersModule)
      },
      {
        path: ApplicationRoutes.stockable,
        loadChildren: () =>
          import('./features/user-panel/stockable/stockable.module').then((m) => m.StockableModule)
      }
    ]
  },
  {
    canActivate: [CurrentUserGuard],
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./features/user-account/user-account.module').then((m) => m.UserAccountModule)
  },
  {
    path: ApplicationRoutes.survey,
    component: PublicLayoutComponent,
    loadChildren: () => import('./features/public/survey/survey.module').then((m) => m.SurveyModule)
  },

  // This is a component-less route and all its functionallity handle on PerformancePackageGpGuard
  {
    path: ApplicationRoutes.performancePackageGp,
    canActivate: [PerformancePackageGpGuard],
    children: [{ path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.shopPerformance }]
  },

  // This is a component-less route and all its functionallity handle on GoogleGuard
  {
    path: ApplicationRoutes.google,
    canActivate: [GoogleGuard],
    children: [{ path: '', pathMatch: 'full', redirectTo: ApplicationRoutes.reviews }]
  },

  // TODO: add 404 page
  { path: '**', pathMatch: 'full', redirectTo: ApplicationRoutes.dashboard }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { ApplicationRoutes } from '../../../app-routes-names'

export const TiresRoutes = {
  list: 'list',
  summaryProgram: 'summary-program',
  summarylevels: 'summary-levels',
  summaryStockable: 'summary-stockable',
  lostTire: 'lost-tire',
  lostTiresList: 'lost-tire-list',
  offers: 'offers'
}

const { tires } = ApplicationRoutes

export const TiresPaths = {
  list: `/${tires}/${TiresRoutes.list}`,
  summaryProgram: `/${tires}/${TiresRoutes.summaryProgram}`,
  summarylevels: `/${tires}/${TiresRoutes.summarylevels}`,
  summaryStockable: `/${tires}/${TiresRoutes.summaryStockable}`,
  lostTire: `/${tires}/${TiresRoutes.lostTire}`,
  lostTiresList: `/${tires}/${TiresRoutes.lostTiresList}`,
  offers: `/${tires}/${TiresRoutes.offers}`
}

export enum Permission {
  Reviews = 'Reviews',
  Receivables = 'Receivables',
  Invoices = 'Invoices',
  Contacts = 'Contacts',
  Calls = 'Calls',
  Reports = 'Reports',
  Inventories = 'Inventories',
  Admin = 'Admin',
  Promotions = 'Promotions',
  PackageGp = 'PackageGp',
  Tracker = 'Tracker',
  LostSales = 'LostSales',
  LostTiresReport = 'LostTiresReport',
  Tires = 'Tires',
  WhoToCall = 'WhoToCall',
  WorkOrders = 'WorkOrders'
}

export const PermissionToLabel: { [key: string]: string } = {
  Reviews: 'Reviews',
  Receivables: 'Receivables',
  Invoices: 'Invoices',
  Contacts: 'Contacts',
  Calls: 'Calls',
  Reports: 'Reports',
  Inventories: 'Inventories',
  Admin: 'Admin',
  Promotions: 'Promotions',
  PackageGp: 'Package Gp',
  Tracker: 'Tracker',
  LostSales: 'Lost Sales',
  LostTiresReport: 'Lost Tires Report',
  Tires: 'Tires',
  WhoToCall: 'Who to Call',
  WorkOrders: 'Work Orders'
}

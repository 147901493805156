import { ILostTiresListResponse } from '../../interfaces/lost-tires-list.interface'
import { ILostTireResponse } from '../../interfaces/lost-tires.interface'
import { ITiresByLevelResponse } from '../../interfaces/tires-by-level.interface'
import { ITiresByStockableResponse } from '../../interfaces/tires-by-stockable.interface'
import { ITiresListResponse } from '../../interfaces/tires-list.interface'
import { ITiresResponse } from '../../interfaces/tires.interface'

export interface TiresStateModel {
  tires: ITiresResponse
  tiresByLevel: ITiresByLevelResponse
  tiresByStockable: ITiresByStockableResponse
  listResponse: ITiresListResponse
  lostTireInfo: ILostTireResponse
  lostTireslistResponse: ILostTiresListResponse
}

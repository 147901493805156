<div class="w-full relative">
  <svg-icon
    *ngIf="showCloseButton"
    class="absolute top-3 left-[14px] cursor-pointer"
    key="close"
    color="#5E5E5E"
    fontSize="10px"
    (click)="invoiceInfoClosed.emit()"
  ></svg-icon>

  <div class="pt-6.5 px-5">
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container *ngIf="invoiceResponse$ | async as info">
        <div
          *ngIf="forModal && info.invoice?.locationName"
          class="absolute z-50 top-3 left-5 font-medium"
        >
          {{ info.invoice.locationName }}
        </div>

        <!-- Invoice Number -->
        <div
          class="flex flex-col md:flex-row md:items-center w-full text-txt bg-primary-light px-1 py-0.5"
        >
          <div class="flex-1 flex items-center font-medium text-xl leading-2xl">
            <div>Invoice: {{ info.invoice.invoiceNumber }}</div>

            <div
              ngxClipboard
              [cbContent]="info.invoice.invoiceNumber.toString()"
              (cbOnSuccess)="toastrService.success('Invoice number copied')"
              class="pl-1 cursor-pointer"
            >
              <svg-icon fontSize="14px" key="file-copy"></svg-icon>
            </div>
          </div>

          <a
            *ngIf="forModal && info.contact && info.contact.contactId !== emptyGuid"
            class="flex-1 cursor-pointer font-medium"
            target="_blank"
            [href]="
              websiteUrl +
              '/contacts/detail/' +
              info.contact.contactId +
              '/' +
              info.invoice.locationId +
              '/invoice/' +
              invoiceId
            "
          >
            <span *ngIf="info.contact.company">
              {{ info.contact.company }}
            </span>
            <span
              *ngIf="
                info.contact.company &&
                info.contact.company !== '' &&
                ((info.contact.firstName && info.contact.firstName !== '') ||
                  (info.contact.lastName && info.contact.lastName !== ''))
              "
            >
              -
            </span>
            <span class="mr-1">{{ info.contact.firstName }}</span>
            <span>{{ info.contact.lastName }}</span>
          </a>
        </div>

        <!-- Base Info -->
        <div class="mt-3 flex flex-col md:flex-row">
          <div *ngIf="info.invoice as baseInfo" class="flex-1">
            <div class="base-info-item">Date: {{ baseInfo.invoiceTime | date : 'M/d/yy h:mm a' }}</div>
            <div class="base-info-item">Work Order #: {{ baseInfo.workOrder }}</div>
            <div class="base-info-item">Service Advisor: {{ baseInfo.serviceAdvisor }}</div>
            <div class="base-info-item">Technician: {{ baseInfo.technician }}</div>
          </div>
          <div *ngIf="info.vehicle as vehicle" class="flex-1 pt-0.5 md:pt-0">
            <div class="base-info-item">Plate: {{ vehicle.plate }}</div>
            <div class="base-info-item">Description: {{ vehicle.description }}</div>
            <div class="base-info-item">Vin: {{ vehicle.vin }}</div>
            <div class="base-info-item">Odometer: {{ vehicle.odometer }}</div>
          </div>
        </div>

        <!-- Concern -->
        <ng-container *ngIf="info.concern as concerns">
          <div class="section-title text-error border-error">Concern</div>
          <div>
            <ng-container *ngFor="let item of concerns">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Service -->
        <ng-container *ngIf="info.service as services">
          <div class="section-title text-primary-dark border-primary-dark">Service</div>
          <div>
            <ng-container *ngFor="let item of services">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Inspection -->
        <ng-container *ngIf="info.inspection as inspection">
          <div class="section-title text-primary-dark border-primary-dark">Inspection</div>
          <div>
            <ng-container *ngFor="let item of inspection">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- General Credits -->
        <ng-container *ngIf="info.generalCredits as generalCredits">
          <div class="section-title text-primary-dark border-primary-dark">General Credits</div>
          <div>
            <ng-container *ngFor="let item of generalCredits">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Deferred Service -->
        <ng-container *ngIf="info.deferredService as deferredService">
          <div class="section-title text-primary-dark border-primary-dark">Deferred Service</div>
          <div>
            <ng-container *ngFor="let item of deferredService">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Deferred Concern -->
        <ng-container *ngIf="info.deferredConcern as deferredConcern">
          <div class="section-title text-primary-dark border-primary-dark">Deferred Concern</div>
          <div>
            <ng-container *ngFor="let item of deferredConcern">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Deferred Inspection -->
        <ng-container *ngIf="info.deferredInspection as deferredInspection">
          <div class="section-title text-primary-dark border-primary-dark">Deferred Inspection</div>
          <div>
            <ng-container *ngFor="let item of deferredInspection">
              <ng-container
                *ngTemplateOutlet="servicePackageItem; context: { item: item }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- Grand total -->
        <ng-container *ngIf="info.summary as summary">
          <div class="section-title text-primary-dark border-primary-dark">Grand total</div>
          <div>
            <table class="w-full mt-3">
              <tr class="grand-toatl-row">
                <td>Total Parts</td>
                <td>{{ summary.partsTotalFormatted }}</td>
              </tr>
              <tr class="grand-toatl-row">
                <td>Total Labor</td>
                <td>{{ summary.laborTotalFormatted }}</td>
              </tr>
              <tr class="grand-toatl-row">
                <td>Other Charges</td>
                <td>{{ summary.otherChargeTotalFormatted }}</td>
              </tr>
              <tr class="grand-toatl-row">
                <td>Total Without TAX</td>
                <td>{{ summary.totalWithoutTaxFormatted }}</td>
              </tr>
              <tr class="total-row">
                <td class="!font-semibold !text-txt px-1">Grand Total</td>
                <td class="!font-semibold !text-txt text-right px-1">
                  {{ summary.grandTotalFormatted }}
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="flex justify-center mt-3">
    <mat-progress-spinner [diameter]="80" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #servicePackageItem let-item="item">
  <div class="mt-3 pb-3 last:pb-0 border-b last:border-b-0 border-gray-200">
    <div class="font-medium text-lg leading-xl">{{ item.header }}</div>
    <div *ngIf="item.description" class="mt-0.5 text-lg leading-xl text-txt-secondary">
      {{ item.description }}
    </div>
    <div title="" class="w-full overflow-x-auto beautyScroll">
      <table class="w-max md:w-full mt-3" *ngIf="item.lines && item.lines.length > 0">
        <tr *ngFor="let line of item.lines">
          <td class="max-w-[200px] md:max-w-[auto] md:w-1/2 px-1">
            {{ line.description }}
          </td>
          <td class="text-right">
            <div class="pl-1 pr-[3px]" *ngIf="line.quantity !== undefined && line.quantity !== null">
              {{ line.quantity }}
            </div>
          </td>
          <td class="p-0">
            <div class="pr-1 w-max" *ngIf="line.priceUnit && line.priceUnit !== ''">
              / {{ line.priceUnit }}
            </div>
          </td>
          <td class="text-right">
            <div class="pl-1 pr-[3px]" *ngIf="line.priceFormatted">
              {{ line.priceFormatted }}
            </div>
          </td>
          <td class="p-0">
            <div class="pr-1 w-max" *ngIf="line.priceUnit && line.priceUnit !== ''">
              / {{ line.priceUnit }}
            </div>
          </td>
          <td class="text-right">
            <div class="px-1" *ngIf="line.totalFormatted">
              {{ line.totalFormatted }}
            </div>
          </td>
          <td class="w-0 p-0">
            <div
              class="pr-1"
              *ngIf="line.totalFormatted && line.otherChargeCode && line.otherChargeCode !== ''"
            >
              {{ line.otherChargeCode }}
            </div>
          </td>
        </tr>
        <tr
          *ngIf="item.subTotalDiscountFormatted && item.subTotalDiscountFormatted !== '$0.00'"
          class="total-row"
        >
          <td class="!font-semibold !text-error-dark px-1">Discount</td>
          <td colspan="5" class="!font-semibold !text-error-dark text-right px-1">
            ({{ item.subTotalDiscountFormatted }})
          </td>
          <td></td>
        </tr>
        <tr class="total-row">
          <td class="!font-semibold !text-txt px-1">Sub total</td>
          <td colspan="5" class="!font-semibold !text-txt text-right px-1">
            {{ item.subTotalFormatted }}
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>

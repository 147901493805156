import { ITimePeriod, IUrlFilters } from '@lla-platform/core/core-data-access'
import { ITireInfo } from './tires.interface'

export interface ITireByStockableItem {
  locationId: string
  locationName: string
  stockableTires: ITireInfo
  notStockableTires: ITireInfo
  tires: ITireInfo
  stockableAccessories: ITireInfo
  notStockableAccessories: ITireInfo
  accessories: ITireInfo
  totals: ITireInfo
}

export interface ITireByStockableItemExtended extends IUrlFilters {
  locationId?: string
  locationName?: string

  stockableTiresEntries: number
  stockableTiresQuantity: number
  stockableTiresPercentage: number
  stockableTiresFormattedQuantity: string

  notStockableTiresEntries: number
  notStockableTiresQuantity: number
  notStockableTiresPercentage: number
  notStockableTiresFormattedQuantity: string

  tiresEntries: number
  tiresQuantity: number
  tiresFormattedQuantity: string

  stockableAccessoriesEntries: number
  stockableAccessoriesQuantity: number
  stockableAccessoriesPercentage: number
  stockableAccessoriesFormattedQuantity: string

  notStockableAccessoriesEntries: number
  notStockableAccessoriesQuantity: number
  notStockableAccessoriesPercentage: number
  notStockableAccessoriesFormattedQuantity: string

  accessoriesEntries: number
  accessoriesQuantity: number
  accessoriesFormattedQuantity: string

  totalsEntries: number
  totalsQuantity: number
  totalsFormattedQuantity: string
}

export interface ITiresByStockableResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByStockableItem[]
  grandTotalStockableTires: ITireInfo
  grandTotalNotStockableTires: ITireInfo
  grandTotalTires: ITireInfo
  grandTotalStockableAccessories: ITireInfo
  grandTotalNotStockableAccessories: ITireInfo
  grandTotalAccessories: ITireInfo
  globalTotals: ITireInfo
}

export interface ITiresByStockableExtendedResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByStockableItemExtended[]
  grandTotal: ITireByStockableItemExtended
}

import { IStockableItems } from '../../interfaces/stockable.interface'

export class GetAllStockable {
  static readonly type = '[stockable] Get All Stockable'
  constructor(public locationId: string) {}
}

export class SaveAllStockable {
  static readonly type = '[stockable] Save All Stockable'
  constructor(public locationId: string, public payload: IStockableItems) {}
}

export class DeleteStockable {
  static readonly type = '[stockable] Delete Stockable'
  constructor(public locationId: string, public stockableId: string) {}
}

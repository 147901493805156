import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { TiresStateModel } from './tires.model'
import { TiresService } from '../tires.service'
import { ITiresExtendedResponse } from '../../interfaces/tires.interface'
import {
  AddLostTire,
  GetLostTireInfo,
  GetLostTiresList,
  GetTiresByLevelSummary,
  GetTiresByStockableSummary,
  GetTiresList,
  GetTiresSummary,
  UpdateTireItemIsTire
} from './tires.actions'
import { ITiresListResponse } from '../../interfaces/tires-list.interface'
import { TireType } from '../../enums/tire-type.enum'
import { TireLevelType } from '../../enums/tire-level-type.enum'
import { ITiresByLevelExtendedResponse } from '../../interfaces/tires-by-level.interface'
import { ITiresByStockableExtendedResponse } from '../../interfaces/tires-by-stockable.interface'

@State<TiresStateModel>({
  name: 'tires'
})
@Injectable()
export class TiresState {
  constructor(private tiresService: TiresService, private tableFilterService: TableFilterService) {}

  @Selector()
  static tires(state: TiresStateModel): ITiresExtendedResponse | undefined {
    if (!state.tires) {
      return
    }
    return {
      timePeriod: state.tires.timePeriod,
      locationTireSummary: state.tires.locationTireSummary.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,
        advantaTiresEntries: el.advantaTires?.entries ?? 0,
        advantaTiresQuantity: el.advantaTires?.quantity ?? 0,
        advantaTiresFormattedQuantity: el.advantaTires?.formattedQuantity ?? '',
        advantaTiresPercentage: this.calculatePercentage(el.advantaTires?.quantity, el.tires?.quantity),
        tiresInProgramEntries: el.tiresInProgram?.entries ?? 0,
        tiresInProgramQuantity: el.tiresInProgram?.quantity ?? 0,
        tiresInProgramFormattedQuantity: el.tiresInProgram?.formattedQuantity ?? '',
        tiresInProgramPercentage: this.calculatePercentage(
          el.tiresInProgram?.quantity,
          el.tires?.quantity
        ),
        bfsTiresEntries: el.bfsTires?.entries ?? 0,
        bfsTiresQuantity: el.bfsTires?.quantity ?? 0,
        bfsTiresFormattedQuantity: el.bfsTires?.formattedQuantity ?? '',
        bfsTiresPercentage: this.calculatePercentage(el.bfsTires?.quantity, el.tires?.quantity),
        tiresEntries: el.tires?.entries ?? 0,
        tiresQuantity: el.tires?.quantity ?? 0,
        tiresFormattedQuantity: el.tires?.formattedQuantity ?? '',
        tiresAndAccessoriesEntries: el.tiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: el.tiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity: el.tiresAndAccessories?.formattedQuantity ?? '',
        totalsEntries: el.totals?.entries ?? 0,
        totalsQuantity: el.totals?.quantity ?? 0,
        totalsFormattedQuantity: el.totals?.formattedQuantity ?? ''
      })),
      grandTotal: {
        advantaTiresEntries: state.tires.grandTotalAdvantaTires?.entries ?? 0,
        advantaTiresQuantity: state.tires.grandTotalAdvantaTires?.quantity ?? 0,
        advantaTiresFormattedQuantity: state.tires.grandTotalAdvantaTires?.formattedQuantity ?? '',
        advantaTiresPercentage: this.calculatePercentage(
          state.tires.grandTotalAdvantaTires?.quantity,
          state.tires.grandTotalTires?.quantity
        ),
        tiresInProgramEntries: state.tires.grandTotalTiresInProgram?.entries ?? 0,
        tiresInProgramQuantity: state.tires.grandTotalTiresInProgram?.quantity ?? 0,
        tiresInProgramFormattedQuantity: state.tires.grandTotalTiresInProgram?.formattedQuantity ?? '',
        tiresInProgramPercentage: this.calculatePercentage(
          state.tires.grandTotalTiresInProgram?.quantity,
          state.tires.grandTotalTires?.quantity
        ),
        bfsTiresEntries: state.tires.grandTotalBfsTires?.entries ?? 0,
        bfsTiresQuantity: state.tires.grandTotalBfsTires?.quantity ?? 0,
        bfsTiresFormattedQuantity: state.tires.grandTotalBfsTires?.formattedQuantity ?? '',
        bfsTiresPercentage: this.calculatePercentage(
          state.tires.grandTotalBfsTires?.quantity,
          state.tires.grandTotalTires?.quantity
        ),
        tiresEntries: state.tires.grandTotalTires?.entries ?? 0,
        tiresQuantity: state.tires.grandTotalTires?.quantity ?? 0,
        tiresFormattedQuantity: state.tires.grandTotalTires?.formattedQuantity ?? '',
        tiresAndAccessoriesEntries: state.tires.grandTotalTiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: state.tires.grandTotalTiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity:
          state.tires.grandTotalTiresAndAccessories?.formattedQuantity ?? '',
        totalsEntries: state.tires.globalTotals?.entries ?? 0,
        totalsQuantity: state.tires.globalTotals?.quantity ?? 0,
        totalsFormattedQuantity: state.tires.globalTotals?.formattedQuantity ?? ''
      }
    }
  }

  @Selector()
  static tiresByLevel(state: TiresStateModel): ITiresByLevelExtendedResponse | undefined {
    if (!state.tiresByLevel) {
      return
    }
    return {
      timePeriod: state.tiresByLevel.timePeriod,
      locationTireSummary: state.tiresByLevel.locationTireSummary.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,

        level1Entries: el.level1?.entries ?? 0,
        level1Quantity: el.level1?.quantity ?? 0,
        level1FormattedQuantity: el.level1?.formattedQuantity ?? '',
        level1Percentage: this.calculatePercentage(el.level1?.quantity, el.tires?.quantity),

        level2Entries: el.level2?.entries ?? 0,
        level2Quantity: el.level2?.quantity ?? 0,
        level2FormattedQuantity: el.level2?.formattedQuantity ?? '',
        level2Percentage: this.calculatePercentage(el.level2?.quantity, el.tires?.quantity),

        level3Entries: el.level3?.entries ?? 0,
        level3Quantity: el.level3?.quantity ?? 0,
        level3FormattedQuantity: el.level3?.formattedQuantity ?? '',
        level3Percentage: this.calculatePercentage(el.level3?.quantity, el.tires?.quantity),

        level4Entries: el.level4?.entries ?? 0,
        level4Quantity: el.level4?.quantity ?? 0,
        level4FormattedQuantity: el.level4?.formattedQuantity ?? '',
        level4Percentage: this.calculatePercentage(el.level4?.quantity, el.tires?.quantity),

        level5Entries: el.level5?.entries ?? 0,
        level5Quantity: el.level5?.quantity ?? 0,
        level5FormattedQuantity: el.level5?.formattedQuantity ?? '',
        level5Percentage: this.calculatePercentage(el.level5?.quantity, el.tires?.quantity),

        level6Entries: el.level6?.entries ?? 0,
        level6Quantity: el.level6?.quantity ?? 0,
        level6FormattedQuantity: el.level6?.formattedQuantity ?? '',
        level6Percentage: this.calculatePercentage(el.level6?.quantity, el.tires?.quantity),

        tiresInLevelEntries: el.tiresInLevel?.entries ?? 0,
        tiresInLevelQuantity: el.tiresInLevel?.quantity ?? 0,
        tiresInLevelFormattedQuantity: el.tiresInLevel?.formattedQuantity ?? '',
        tiresInLevelPercentage: this.calculatePercentage(el.tiresInLevel?.quantity, el.tires?.quantity),

        tiresEntries: el.tires?.entries ?? 0,
        tiresQuantity: el.tires?.quantity ?? 0,
        tiresFormattedQuantity: el.tires?.formattedQuantity ?? '',

        tiresAndAccessoriesEntries: el.tiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: el.tiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity: el.tiresAndAccessories?.formattedQuantity ?? '',

        totalsEntries: el.totals?.entries ?? 0,
        totalsQuantity: el.totals?.quantity ?? 0,
        totalsFormattedQuantity: el.totals?.formattedQuantity ?? ''
      })),
      grandTotal: {
        level1Entries: state.tiresByLevel.grandTotalLevel1?.entries ?? 0,
        level1Quantity: state.tiresByLevel.grandTotalLevel1?.quantity ?? 0,
        level1FormattedQuantity: state.tiresByLevel.grandTotalLevel1?.formattedQuantity ?? '',
        level1Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel1?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        level2Entries: state.tiresByLevel.grandTotalLevel2?.entries ?? 0,
        level2Quantity: state.tiresByLevel.grandTotalLevel2?.quantity ?? 0,
        level2FormattedQuantity: state.tiresByLevel.grandTotalLevel2?.formattedQuantity ?? '',
        level2Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel2?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        level3Entries: state.tiresByLevel.grandTotalLevel3?.entries ?? 0,
        level3Quantity: state.tiresByLevel.grandTotalLevel3?.quantity ?? 0,
        level3FormattedQuantity: state.tiresByLevel.grandTotalLevel3?.formattedQuantity ?? '',
        level3Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel3?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        level4Entries: state.tiresByLevel.grandTotalLevel4?.entries ?? 0,
        level4Quantity: state.tiresByLevel.grandTotalLevel4?.quantity ?? 0,
        level4FormattedQuantity: state.tiresByLevel.grandTotalLevel4?.formattedQuantity ?? '',
        level4Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel4?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        level5Entries: state.tiresByLevel.grandTotalLevel5?.entries ?? 0,
        level5Quantity: state.tiresByLevel.grandTotalLevel5?.quantity ?? 0,
        level5FormattedQuantity: state.tiresByLevel.grandTotalLevel5?.formattedQuantity ?? '',
        level5Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel5?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        level6Entries: state.tiresByLevel.grandTotalLevel6?.entries ?? 0,
        level6Quantity: state.tiresByLevel.grandTotalLevel6?.quantity ?? 0,
        level6FormattedQuantity: state.tiresByLevel.grandTotalLevel6?.formattedQuantity ?? '',
        level6Percentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalLevel6?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        tiresInLevelEntries: state.tiresByLevel.grandTotalTiresInLevel?.entries ?? 0,
        tiresInLevelQuantity: state.tiresByLevel.grandTotalTiresInLevel?.quantity ?? 0,
        tiresInLevelFormattedQuantity:
          state.tiresByLevel.grandTotalTiresInLevel?.formattedQuantity ?? '',
        tiresInLevelPercentage: this.calculatePercentage(
          state.tiresByLevel.grandTotalTiresInLevel?.quantity,
          state.tiresByLevel.grandTotalTires?.quantity
        ),

        tiresEntries: state.tiresByLevel.grandTotalTires?.entries ?? 0,
        tiresQuantity: state.tiresByLevel.grandTotalTires?.quantity ?? 0,
        tiresFormattedQuantity: state.tiresByLevel.grandTotalTires?.formattedQuantity ?? '',

        tiresAndAccessoriesEntries: state.tiresByLevel.grandTotalTiresAndAccessories?.entries ?? 0,
        tiresAndAccessoriesQuantity: state.tiresByLevel.grandTotalTiresAndAccessories?.quantity ?? 0,
        tiresAndAccessoriesFormattedQuantity:
          state.tiresByLevel.grandTotalTiresAndAccessories?.formattedQuantity ?? '',

        totalsEntries: state.tiresByLevel.globalTotals?.entries ?? 0,
        totalsQuantity: state.tiresByLevel.globalTotals?.quantity ?? 0,
        totalsFormattedQuantity: state.tiresByLevel.globalTotals?.formattedQuantity ?? ''
      }
    }
  }

  @Selector()
  static tiresByStockable(state: TiresStateModel): ITiresByStockableExtendedResponse | undefined {
    if (!state.tiresByStockable) {
      return
    }
    return {
      timePeriod: state.tiresByStockable.timePeriod,
      locationTireSummary: state.tiresByStockable.locationTireSummary.map((el) => ({
        locationId: el.locationId,
        locationName: el.locationName,

        stockableTiresEntries: el.stockableTires?.entries ?? 0,
        stockableTiresQuantity: el.stockableTires?.quantity ?? 0,
        stockableTiresFormattedQuantity: el.stockableTires?.formattedQuantity ?? '',
        stockableTiresPercentage: this.calculatePercentage(
          el.stockableTires?.quantity,
          el.tires?.quantity
        ),

        notStockableTiresEntries: el.notStockableTires?.entries ?? 0,
        notStockableTiresQuantity: el.notStockableTires?.quantity ?? 0,
        notStockableTiresFormattedQuantity: el.notStockableTires?.formattedQuantity ?? '',
        notStockableTiresPercentage: this.calculatePercentage(
          el.notStockableTires?.quantity,
          el.tires?.quantity
        ),

        tiresEntries: el.tires?.entries ?? 0,
        tiresQuantity: el.tires?.quantity ?? 0,
        tiresFormattedQuantity: el.tires?.formattedQuantity ?? '',

        stockableAccessoriesEntries: el.stockableAccessories?.entries ?? 0,
        stockableAccessoriesQuantity: el.stockableAccessories?.quantity ?? 0,
        stockableAccessoriesFormattedQuantity: el.stockableAccessories?.formattedQuantity ?? '',
        stockableAccessoriesPercentage: this.calculatePercentage(
          el.stockableAccessories?.quantity,
          el.accessories?.quantity
        ),

        notStockableAccessoriesEntries: el.notStockableAccessories?.entries ?? 0,
        notStockableAccessoriesQuantity: el.notStockableAccessories?.quantity ?? 0,
        notStockableAccessoriesFormattedQuantity: el.notStockableAccessories?.formattedQuantity ?? '',
        notStockableAccessoriesPercentage: this.calculatePercentage(
          el.notStockableAccessories?.quantity,
          el.accessories?.quantity
        ),

        accessoriesEntries: el.accessories?.entries ?? 0,
        accessoriesQuantity: el.accessories?.quantity ?? 0,
        accessoriesFormattedQuantity: el.accessories?.formattedQuantity ?? '',

        totalsEntries: el.totals?.entries ?? 0,
        totalsQuantity: el.totals?.quantity ?? 0,
        totalsFormattedQuantity: el.totals?.formattedQuantity ?? ''
      })),
      grandTotal: {
        stockableTiresEntries: state.tiresByStockable.grandTotalStockableTires?.entries ?? 0,
        stockableTiresQuantity: state.tiresByStockable.grandTotalStockableTires?.quantity ?? 0,
        stockableTiresFormattedQuantity:
          state.tiresByStockable.grandTotalStockableTires?.formattedQuantity ?? '',
        stockableTiresPercentage: this.calculatePercentage(
          state.tiresByStockable.grandTotalStockableTires?.quantity,
          state.tiresByStockable.grandTotalTires?.quantity
        ),

        notStockableTiresEntries: state.tiresByStockable.grandTotalNotStockableTires?.entries ?? 0,
        notStockableTiresQuantity: state.tiresByStockable.grandTotalNotStockableTires?.quantity ?? 0,
        notStockableTiresFormattedQuantity:
          state.tiresByStockable.grandTotalNotStockableTires?.formattedQuantity ?? '',
        notStockableTiresPercentage: this.calculatePercentage(
          state.tiresByStockable.grandTotalNotStockableTires?.quantity,
          state.tiresByStockable.grandTotalTires?.quantity
        ),

        tiresEntries: state.tiresByStockable.grandTotalTires?.entries ?? 0,
        tiresQuantity: state.tiresByStockable.grandTotalTires?.quantity ?? 0,
        tiresFormattedQuantity: state.tiresByStockable.grandTotalTires?.formattedQuantity ?? '',

        stockableAccessoriesEntries: state.tiresByStockable.grandTotalStockableAccessories?.entries ?? 0,
        stockableAccessoriesQuantity:
          state.tiresByStockable.grandTotalStockableAccessories?.quantity ?? 0,
        stockableAccessoriesFormattedQuantity:
          state.tiresByStockable.grandTotalStockableAccessories?.formattedQuantity ?? '',
        stockableAccessoriesPercentage: this.calculatePercentage(
          state.tiresByStockable.grandTotalStockableAccessories?.quantity,
          state.tiresByStockable.grandTotalTires?.quantity
        ),

        notStockableAccessoriesEntries:
          state.tiresByStockable.grandTotalNotStockableAccessories?.entries ?? 0,
        notStockableAccessoriesQuantity:
          state.tiresByStockable.grandTotalNotStockableAccessories?.quantity ?? 0,
        notStockableAccessoriesFormattedQuantity:
          state.tiresByStockable.grandTotalNotStockableAccessories?.formattedQuantity ?? '',
        notStockableAccessoriesPercentage: this.calculatePercentage(
          state.tiresByStockable.grandTotalNotStockableAccessories?.quantity,
          state.tiresByStockable.grandTotalTires?.quantity
        ),

        accessoriesEntries: state.tiresByStockable.grandTotalAccessories?.entries ?? 0,
        accessoriesQuantity: state.tiresByStockable.grandTotalAccessories?.quantity ?? 0,
        accessoriesFormattedQuantity:
          state.tiresByStockable.grandTotalAccessories?.formattedQuantity ?? '',

        totalsEntries: state.tiresByStockable.globalTotals?.entries ?? 0,
        totalsQuantity: state.tiresByStockable.globalTotals?.quantity ?? 0,
        totalsFormattedQuantity: state.tiresByStockable.globalTotals?.formattedQuantity ?? ''
      }
    }
  }

  @Selector()
  static listResponse(state: TiresStateModel): ITiresListResponse | undefined {
    if (!state.listResponse) {
      return
    }
    return {
      count: state.listResponse.count,
      totals: state.listResponse.totals,
      tires: state.listResponse.tires?.map((el) => ({
        ...el,
        tireType: el.tireType === TireType.NotSet ? undefined : el.tireType,
        tireLevelType: el.tireLevelType === TireLevelType.NotSet ? undefined : el.tireLevelType
      }))
    }
  }

  @Selector()
  static lostTireslistResponse(state: TiresStateModel) {
    return state.lostTireslistResponse
  }

  @Selector()
  static lostTireInfo(state: TiresStateModel) {
    return state.lostTireInfo
  }

  @Action(GetTiresSummary, { cancelUncompleted: true })
  getTiresSummary(ctx: StateContext<TiresStateModel>, { payload }: GetTiresSummary) {
    return this.tiresService.getTiresSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tires: res
        })
      })
    )
  }

  @Action(GetTiresByLevelSummary, { cancelUncompleted: true })
  getTiresByLevelSummary(ctx: StateContext<TiresStateModel>, { payload }: GetTiresByLevelSummary) {
    return this.tiresService.getTiresByLevelSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tiresByLevel: res
        })
      })
    )
  }

  @Action(GetTiresByStockableSummary, { cancelUncompleted: true })
  getTiresByStockableSummary(
    ctx: StateContext<TiresStateModel>,
    { payload }: GetTiresByStockableSummary
  ) {
    return this.tiresService.getTiresByStockableSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tiresByStockable: res
        })
      })
    )
  }

  @Action(GetTiresList)
  getTiresList(ctx: StateContext<TiresStateModel>, { info }: GetTiresList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.tiresService.getTiresList(this.tableFilterService.createRequestPayload(info)).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }

  @Action(UpdateTireItemIsTire)
  updateTireItemIsTire(ctx: StateContext<TiresStateModel>, { itemId, payload }: UpdateTireItemIsTire) {
    return this.tiresService.updateTire(itemId, payload)
  }

  @Action(GetLostTireInfo)
  getLostTireInfo(ctx: StateContext<TiresStateModel>) {
    ctx.patchState({
      lostTireInfo: undefined
    })
    return this.tiresService.getLostTireInfo().pipe(
      tap((res) => {
        ctx.patchState({
          lostTireInfo: res
        })
      })
    )
  }

  @Action(AddLostTire)
  addLostTire(ctx: StateContext<TiresStateModel>, { payload }: AddLostTire) {
    return this.tiresService.addLostTire(payload)
  }

  @Action(GetLostTiresList)
  getLostTiresList(ctx: StateContext<TiresStateModel>, { info }: GetLostTiresList) {
    ctx.patchState({
      lostTireslistResponse: undefined
    })
    return this.tiresService.getLostTiresList(this.tableFilterService.createRequestPayload(info)).pipe(
      tap((res) => {
        ctx.patchState({
          lostTireslistResponse: res
        })
      })
    )
  }

  static calculatePercentage(value?: number, total?: number) {
    return !total || total === 0 ? 0 : ((value ?? 0) / total) * 100
  }
}

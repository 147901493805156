import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { TireType } from '../enums/tire-type.enum'
import { TireLevelType } from '../enums/tire-level-type.enum'

export interface IUpdateTireRequest {
  isTire: boolean
  tireType: TireType
  tireLevelType: TireLevelType
  isStockable: boolean
}

export interface ITiresListItem extends IAdvancedTable {
  id?: string
  locationId?: string
  contactId?: string
  invoiceId?: string
  locationName?: string
  description?: string
  manufacturer?: string
  partNumber?: string
  size?: string
  unitPrice?: number
  unitPriceFormatted?: string
  quantity: number
  formattedQuantity: string
  invoiceNo?: number
  invoiceDate?: string
  isTire?: boolean
  total: number
  totalFormatted: string
  tireType?: TireType
  tireLevelType?: TireLevelType
  isStockable?: boolean
}

export interface ITiresListTotal extends IAdvancedTable {
  quantity: number
  formattedQuantity: string
  total: number
  formattedTotal: string
}

export interface ITiresListResponse extends IPaginationFilteredResponse {
  tires: ITiresListItem[]
  totals: ITiresListTotal
}
